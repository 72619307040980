<template>
  <div
    class="container size-mobile bg-element-white min-height-second paket"
    style="font-family: Montserrat;padding-left:15px !important;"
  >
  
    <v-dialog />
    <alert-error ref="ae" />
    <form class="d-flex flex-column mt-4" method="POST" @submit.prevent="store()" style="min-height: 88vh;"> 
      <fieldset>
        <legend class="legend-input">Nama Tamu</legend>
        <input type="text" name="modal-input-pengirim" class="form-control" autocomplete="off" v-model="data.nama_tamu" required/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Asal Instansi</legend>
        <input type="text" name="modal-input-penerima" class="form-control" autocomplete="off" v-model="data.asal_instansi" required/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Keperluan</legend>
        <textarea name="tujuan" class="form-control" autocomplete="off" v-model="data.keperluan" required></textarea>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Nama Driver</legend>
        <input type="text" name="modal-input-resi" class="form-control" autocomplete="off" v-model="data.nama_driver"/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Plat Nomor</legend>
        <input type="text" name="no_polisi" class="form-control" autocomplete="off" v-model="data.no_polisi"/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Foto Tamu</legend>
        <a class="btn btn-daftar" href="" style="width: 100% !important;">
            <span class="" style="float:left;margin-right:-15px;margin-top:-3px"><img src="../../assets/img/cam.png" alt="Attach" style="width:25px;"></span>
            Ambil Gambar
            <input id="file" name="file" type='file' class="attach" accept="image/*" capture @change="onFileChange"/>
        </a>
        <img v-if="imgUploaded" :src="imgUploaded" style="object-fit: contain;width: 100%;height: 100%;text-align:center;margin-top: 10px;"/>
      </fieldset>
      <div class="mb-1">
        <legend class="legend-input mt-3">Jenis Kendaraan</legend>
        <div class="d-flex mt-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="status" value="2" v-model="data.jenis_kendaraan"/>
            <label class="form-check-label" for="status">Roda 2</label>
          </div>
          <div class="form-check ml-4">
            <input class="form-check-input" type="radio" name="status" value="4" v-model="data.jenis_kendaraan"/>
            <label class="form-check-label" for="exampleRadios2">Roda 4</label>
          </div>
        </div>
      </div>
      <fieldset>
        <img style="right: 2rem;position: absolute;" src="../../assets/img/arrow-down.png"/>
        <legend class="legend-input">Warna Kartu</legend>
        <select class="form-control" v-model="data.warna_kartu" style="margin-top: -15px !important;">
            <option value="">-- Pilih --</option>
            <option value="biru">BIRU</option>
            <option value="merah">MERAH</option>
            <option value="pink">PINK</option>
            <option value="kuning">KUNING</option>
        </select>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Nomor Kartu</legend>
        <input class="form-control" autocomplete="off" v-model="data.nomor_kartu"/>
      </fieldset>
      <fieldset class="mt-2">
        <legend class="legend-input">Jam Masuk</legend> 
        <!-- <vue-clock-picker v-model="data.jam_masuk" active-color="#1976D2" done-text="Selesai" class="form-control" style="border:0 !important" required></vue-clock-picker> --> 
        <input type="time" name="modal-input-resi" class="form-control" autocomplete="off" v-model="data.jam_masuk" required="required" />

      </fieldset>
        <div class="invalid-feedback" style="display: block;" v-if="showError">
          <span>Jam Masuk Harus diisi</span>
        </div>
       <!-- <fieldset class="mt-2">
        <legend class="legend-input">Tanggal Diambil</legend> 
        <input type="date" name="tanggal-diambil" class="form-control" autocomplete="off" v-model="dataModal.diambil_pada_tanggal" value="2020-09-15"/>
      </fieldset> -->
      <button class="btn-blue mt-3 mb-3" style="width:100%" :class="{'disable': isSubmit}" type="submit">
        <b>Simpan</b>
      </button>
    </form>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import Api from "../../Api";
import alertError from "../../components/AlertError.vue";
// import VueClockPicker from '@pencilpix/vue2-clock-picker';
// import '@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.min.css'; 

export default {
  name: "addTamu",
  components: {
    alertError, 
  },
  data() {
    return {
      showError: false,
      startDate: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
      isLoading: true,
      data: { 
        warna_kartu: '',
        nomor_kartu:'',
        user_id: '',
        nama_tamu: '',
        nama_driver: '',
        keperluan: '',
        workplace_id: '',
        file_upload:'',
        no_polisi: '',
        jenis_kendaraan: '4',
        jam_keluar: '',
        jam_masuk: '',
      },
      total: 0,
      dataModal: [],
      imgUploaded:'', 
      isModalVisible: false,
      updatedContent: "",
      token: "",
      myData: "",
      isSubmit: false,
      dataFiltered: 0
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem("token"));
    this.myData = JSON.parse(localStorage.getItem("cred"));
    // console.log(JSON.parse(localStorage.getItem('cred')));
  }, 
  methods: {
    // Preview Image
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.data.file_upload = e.target.files[0]
      // this.uploadActive = true
    },
    async store() { 
      if(this.data.jam_masuk == ''){
        this.showError = true
        return true
      }
      try { 
        this.data.user_id = this.myData.id;
        this.data.workplace_id = this.myData.id_tempat_kerja;
        let formData = new FormData();
        for (var key in this.data) { 
            console.log(key, this.data[key]);
            formData.append(key, this.data[key]);
        }  
        this.isSubmit = true

        await Api.post("satpam/tamu", formData, {
          headers: { 
            Authorization: "Bearer " + this.token,
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          this.$router.go(-1)
          // this.$router.push(this.$route.query.redirect || '/tamu')
          console.log(response);
            // this.done   = true;
        });
      } catch (error) {
        console.log(error);
      }
      this.isSubmit = false
    }
  }
};
</script> 
<style>
.clock-picker__input{
    min-width: 100% !important;
}
</style>